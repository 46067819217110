<template>
    <div class="page">
        <div class="head">
            <span>咨询订单</span>
        </div>
        <div class="subtitle">
            <div class="subtitle-item" style="width: 100%;height: 10vh;background-color: #fff;">
                <div>
                    <Input @on-change="inputchange" v-model="storePrams.keywords" placeholder="请选择手机号、初始地、目的地" style="width: 350px;margin-right: 20px;"><Icon type="md-search" slot="suffix" /></Input>
                    <span class="input-span">下单时间</span><DatePicker type="daterange" @on-change="chooseDate" placement="bottom-end" placeholder="请选择下单时间" style="width: 300px"></DatePicker>
                </div>
                <div>
                    <Button type="primary" @click="searchSubmit" style="margin-right: 20px;">搜索</Button>
                    <Button @click="exportData">导出</Button>
                </div>
            </div>
        </div>
        <div class="content">
            <div style="width: 100%;background-color: #fff;padding: 20px;">
                <Table  ref="table" :columns="columnsTable" :data="dataTable" border>
                    <template slot-scope="{ index }" slot="id">
                        <div>{{ index + 1 }}</div>
                    </template>
                    <template slot-scope="{ row, index }" slot="car">
                       <div>{{row.car.name}}</div>
                    </template>
                </Table>
                <Page class="Page" :total="total" show-elevator show-total @on-change="pageChange" :current="storePrams.page" :page-size="storePrams.limit"/>
            </div>
        </div>
    </div>
</template>

<script>
import { adviceList,adviceExport} from "@/api/index";
    export default {
        data() {
            return {
                columnsTable: [
                    {title: '#',slot: "id",align:'center'},
                    {title: '初始地',key: 'initially_address',align:'center'},
                    {title: '目的地',key: 'destination_address',align:'center'},
                    {title: '车辆',slot: 'car',align:'center'},
                    {title: '电话',key: 'phone',align:'center'},
                    {title: '下单时间',key: 'created_at',align:'center'},
                ],
                dataTable: [
                    {
                        initially_address: '',
                        destination_address: '',
                        car: {},
                        phone: '',
                        created_at: '',
                    },
                ],
                total: 0,
                storePrams: {
                    page: 1,
                    limit: 30,
                    keywords:'',
                    start_date:'',
                    end_date:'',
                },
            }
        },
        created(){
            this.adviceList()
        },
        methods: {
            chooseDate(e){
                if(e[0]){
                    this.storePrams.start_date = e[0]
                    this.storePrams.end_date = e[1]
                }
            },
            adviceList(){
                adviceList(this.storePrams).then(res=>{
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            inputchange(e) {
            },
            searchSubmit(){
                console.log('搜索提交')
                this.adviceList()
            },
            //导出
            exportData(){    
                this.adviceList()            
                let data = {
                    keywords: this.storePrams.keywords,
                    start_date: this.storePrams.start_date,
                    end_date: this.storePrams.end_date,
                }
                adviceExport(data).then(res=>{
                    window.open(res.data)
                })
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.adviceList()
            },
        },
    }
</script>

<style lang="scss" scoped>
.input-span{
    margin-right: 10px;
    width: 56px;
    height: 12px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}
.page{
    background-color: #F5F9FA;
    height: 100%;
}
.head{
    width: 100%;
    height: 70px;
    background-color: #fff;
    margin-bottom: 10px;
    span{
        width: 64px;
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        float: left;
        margin: 20px 0 0 20px;
    }
}
.subtitle{
    width: 100%;
    padding: 0 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    .subtitle-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
    }
}
.content{
    width: 100%;
    padding: 0 20px;
    border-radius: 10px;
    .Page{
        background-color: #fff;
        margin: 20px 0;
        text-align: right;
    }
}
</style>